html{
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', sans-serif;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #022c43;
  background-image: url('./assets/images/ai_1_low.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: block;
}

p {
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent background, no hard edges */
  color: white; /* Text color */
  padding: 20px; /* Spacing around the text */
  box-shadow: none; /* No shadow to avoid the feeling of a border or frame */
  line-height: 1.6;
  font-size: 1.1rem;
  margin: 10px 0; /* Space between paragraphs */
  text-align: center;
  position: relative;
  width: auto; /* Let the element adjust based on its content */
  max-width: none; /* No max width constraint */
  overflow: hidden;
  /* No border or border radius, so it blends with its container */
  border: none; 
  border-radius: 0;
  text-shadow: 2px 2px 4px rgba(139, 0, 0, 0.8);
}

p:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}


