.home-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }
    h1 {
        color: #fff;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;
        text-shadow: 2px 2px 4px rgba(139, 0, 0, 0.8);
        &:before {
            content: '<h1>';
            font-family: 'La Belle Aurore', cursive;;
            color:#ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }
        &:after {
            content: '<h1/>';
            font-family: 'La Belle Aurore', cursive;
            color:#ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }
        img {
            width: 32px;
            margin-left: 20px;
            opacity: 0;
            height: 42px;//auto
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;

        }
    }
    h2 {
        color: #f06564;
        margin-top: 20px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
        background: transparent; /* Transparent background */
        text-shadow: 2px 2px 4px rgba(139, 0, 0, 0.8);
    }

    .flat-button {
        color: #ffd700;
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        float: left;
        animation: fadeInAnimation 1s 1.8s backwards;
        white-space: nowrap;
        &:hover {
            background: #ffd700;
            color: #333;
            
        }
    }

}

  