/* Container for the Logo */
.logo-container {
  z-index: 0;
  width: 400px;
  height: 609px;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;
  // filter:blur(1px);
  /* SVG styles */
  svg {
    width: 100%;
    height: auto;
    bottom: 0;
    transform: rotateY(180deg) !important;
  }
}

/* SVG Path Drawing Animation */
.svg-container path {
  /* Smoothing out the edges of the lines */
  stroke-linejoin: round;  /* Smooth corner joins */
  stroke-linecap: round;   /* Smooth line ends */
  stroke-width: 13  ;        /* Adjust stroke width */
  opacity: 1;              /* Full opacity for better visibility */
  stroke: rgb(252, 10, 70);         /* Use a solid reddish color for better contrast */

  /* Adding shadow to the path to increase visibility */
  filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.6));
  fill: rgba(104, 46, 46, 0.248);
  /* Drawing effect using stroke-dasharray and stroke-dashoffset */
  stroke-dasharray: 1000;   /* Length of the path (initial value) */
  stroke-dashoffset: 1000;  /* Initially hide the path */

  /* Animate the path drawing effect */
  animation: drawPath 4s ease forwards; /* Animation for the drawing effect */
}

/* Gradient definition for the stroke (optional) */
#softGradient {
  stop-color: rgba(139, 0, 0, 0.7); /* Reddish color for the gradient */
  stop-opacity: 1;
}

/* Keyframes for drawing animation */
@keyframes drawPath {
  to {
    stroke-dashoffset: 0; /* Reveal the path as it's drawn */
  }
}
